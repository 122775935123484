import '/assets/styles/modals/notifications.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

// Partials
import Placeholder from '/views/partials/placeholder';

// Services
import userServices from '/services/user'
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const NotificationsModal = ({ closeBtn, onStare }) => {
	const timerRef = useRef(false);

	const [data, setData] = useState(null);

	useEffect(() => {
		userServices.getNotifications().then((payload) => {
			setData(payload);

			if(onStare) {
				timerRef.current = setTimeout(() => {
					setData(payload.map((p => ({
						...p,
						read_at: 1
					}))));
					onStare();
				}, 3000)
			}
		}).catch(() => {
			close();
		})

		return (() => {
			if(timerRef.current) {
				clearTimeout(timerRef.current);
			}
		})
	}, [])

	const notifications = useMemo(() => (
		data ?? [false, false, false, false, false, false]
	), [data])

	return (
		<>
			<div className="modal-header">
				<strong className="header-title">Bildirimlerim</strong>
				{closeBtn}
			</div>
			<div className="modal-body loader-container">
				<ul className="notifications-list">
					{notifications.map((notification, nth) => (
						notification ? (
							<li
								className={classNames('list-item', { unread: !notification.read_at })}
								key={notification?.id ?? nth}>
								<Icon name="notification" className="item-icon" />
								<div className="item-text">
									<span className="item-title">
										{notification.data?.message}
									</span>

									<span className="item-datetime">
										{notification.formatted_created_at}
									</span>
								</div>
							</li>
						)
						:
						<Placeholder className="list-item" key={nth} />
					))}
				</ul>
			</div>
		</>
	)
}

NotificationsModal.modalConfig = {
	containerClass: 'modal-notifications mobile-full',
}

export default NotificationsModal;